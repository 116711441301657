.base {
    color: var(--color-dark);
}

@media (min-width: 1185px) {
    .lg-Display {
        font-size: var(--font-display-font-size);
        line-height: var(--font-display-line-height);
        padding: 14px 0px 23px;
        font-weight: var(--font-bold-weight);
    }

    .lg-Header {
        font-size: var(--font-header-font-size);
        line-height: var(--font-header-line-height);
        padding: 15px 0px 17px;
        font-weight: var(--font-bold-weight);
    }

    .lg-Subheader1 {
        font-size: var(--font-subheader-font-size);
        line-height: var(--font-subheader-line-height);
        padding: 19px 0px 21px;
        font-weight: var(--font-bold-weight);
    }

    .lg-Subheader2 {
        font-size: var(--font-subheader-font-size);
        line-height: var(--font-subheader-line-height);
        padding: 3px 0px 5px;
        font-weight: var(--font-bold-weight);
    }

    .lg-Lead {
        font-size: var(--font-lead-font-size);
        line-height: var(--font-lead-line-height);
        padding: 15px 0px 17px;
    }

    .lg-Body1 {
        font-size: var(--font-body-font-size);
        line-height: var(--font-body-line-height);
        padding: 24px 0px 23px;
    }

    .lg-Body2 {
        font-size: var(--font-body-font-size);
        line-height: var(--font-body-line-height);
        padding: 8px 0px 7px;
    }

    .lg-Caption1 {
        font-size: var(--font-caption-font-size);
        line-height: var(--font-caption-line-height);
        padding: 10px 0px 11px;
    }

    .lg-Caption2 {
        font-size: var(--font-caption-font-size);
        line-height: var(--font-caption-line-height);
        padding: 2px 0px 3px;
    }
}

@media (max-width: 1184px) {
    .sm-Header, .Header {
        font-size: var(--font-header-font-size);
        line-height: var(--font-header-line-height);
        padding: 12px 0px;
        padding-bottom: 14px;
    }

    .sm-Subheader1, .Subheader1 {
        font-size: var(--font-subheader-font-size);
        line-height: var(--font-subheader-line-height);
        padding: 14px 0px;
    }

    .sm-Subheader2, .Subheader2 {
        font-size: var(--font-subheader-font-size);
        line-height: var(--font-subheader-line-height);
        padding: 6px 0px;
    }

    .sm-Lead, .Lead {
        font-size: var(--font-lead-font-size);
        line-height: var(--font-lead-line-height);
        padding: 15px 0px;
    }

    .sm-Body1, .Body1 {
        font-size: var(--font-body-font-size);
        line-height: var(--font-body-line-height);
        padding: 16px 0px;
    }

    .sm-Body2, .Body2 {
        font-size: var(--font-body-font-size);
        line-height: var(--font-body-line-height);
        padding: 8px 0px;
    }

    .sm-Caption1, .Caption1 {
        font-size: var(--font-caption-font-size);
        line-height: var(--font-caption-line-height);
        padding: 10px 0px 11px;
    }

    .sm-Caption2, .Caption2 {
        font-size: var(--font-caption-font-size);
        line-height: var(--font-caption-line-height);
        padding: 2px 0px 3px;
    }
}
