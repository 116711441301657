/*
    --font-body-font-size: 1.125rem;
    --font-body-line-height: 1.6875rem;

    --font-caption-font-size: 0.8125rem;
    --font-caption-line-height: 1.1875rem;
    --color-primary: rgba(40, 75, 230, 1);
*/

.login-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.35);
    z-index: 10000;

    display: none;
    align-items: center;
    justify-content: center;
}

.login-popup__container {
    position: relative;
    background: white;
    width: 28rem;
    border-radius: 0.5rem;
}

.login-popup__header {
    height: 4em;
    background-color: rgba(26, 26, 26, 0.04);
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-popup__form {
    display: flex;
    flex-direction: column;
    padding: 2em;
    padding-top: 0;
    padding-bottom: 1.8125rem;
}

.login-popup__header-text {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    text-align: center;
}

.main-login-form__form {
    padding-top: 2.25rem;
}

.main-login-form__closeIcon {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
}

.main-login-form__bigCaption {
    font-weight: 700;
}

.main-login-form__instructions {
    margin-top: 0.8125rem;
}

.main-login-form__buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 3rem);
    grid-gap: 0.5rem;
    margin-top: 1.75rem;
    margin-bottom: 1.3125rem;
}

.main-login-form__policies {
    font-weight: 400;
}

.email-login-form__dont-have-profile {
    margin-top: 0.6875rem;
    font-size: 0.8125rem;
    line-height: 1.1875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.login-popup__error-place {
    height: 3rem;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.1875rem;
    color: rgba(255, 50, 35, 1);
    display: flex;
    align-items: center;
}

.email-login-form__form {
    padding-top: 2rem;
}

.email-login-form__forgot-password {
    margin-top: 0.125rem;
    height: 3rem;
}

.email-login-form__forgot-password:hover {
    background-color: rgba(26, 26, 26, 0.1)
}

.login-recovery-form__instructions {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    color: rgba(26, 26, 26, 1);
    margin-top: 2.25rem;
    margin-bottom: 1.375rem;
}

.login-recovery-successful-form_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 19rem;
}

.login-recovery-successful-form_instructions {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    color: rgba(26, 26, 26, 1);
    text-align: center;
}

.login-register-form__form {
    padding-top: 2rem;
}

.login-register-form__social-buttons {
    display: grid;
    grid-template-columns: 3rem 3rem 3rem;
    grid-gap: 0.5rem;
    margin-top: 0.8125rem;
    justify-content: center;
}

.login-register-form__social-button {
    height: 3rem;
    width: 3rem;
    padding: 0 !important;
}

.login-popup__header-button {
    cursor: pointer;
}

.___base-text-button {
    font-weight: 400;
    font-size: inherit;
    line-height: inherit;
    color: rgba(40, 75, 230, 1);
    background-color: transparent;
    box-shadow: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 0.5rem;
}

.___base-link {
    font-weight: 400;
    font-size: inerit;
    line-height: inherit;
    color: rgba(40, 75, 230, 1);
    background-color: transparent;
    box-shadow: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
}

.___base-button {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    box-sizing: border-box;
    max-width: 100%;
    font-weight: 400;
    width: 100%;
    font-family: var(--font-family);
}

.___base-button:hover {
    opacity: 0.9;
}

.___base-button__caption {
    text-align: center;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: rgba(26, 26, 26, 1);
}

.___base-button_with-icon {
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 0.75rem;
}

.___base-button_with-icon .___base-button__caption {
    display: inline-block;
    max-width: calc(100% - 1rem);
}

.___base-button_color_primary {
    background-color: rgba(40, 75, 230, 1);
}

.___base-button_color_primary .___base-button__caption {
    color: #fff;
}

.___base-button_color_dark {
    background-color: rgba(26, 26, 26, 1);
}

.___base-button_color_dark .___base-button__caption {
    color: #fff;
}

.___base-button__icon {
    flex-shrink: 0;
}

.___base-input__container {
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(26, 26, 26, 0.24);
    height: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.___base-input__error-text {
    width: 100%;
}

.___base-input__container_invalid {
    border-bottom: 1px solid rgba(255, 50, 35, 1);
}

.___base-input__container_has-error {
    margin-bottom: calc(1.1875rem - 3px);
}

.___base-input__error-text {
    position: absolute;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.1875rem;
    left: 0.125rem;
    bottom: calc(-1.1875rem - 3px);
    color: rgba(255, 50, 35, 1);
}

.___base-input__container_with-icon {
    padding-right: 1.5rem;
}

.___base-input__input {
    width: 100%;
    height: 2.6rem;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    color: rgba(26, 26, 26, 1);
    border: 0;
    outline: 0;
    flex-grow: 1;
    font-family: var(--font-family);
}

.___base-input__icon {
    position: absolute;
    right: 0;
    bottom: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
}

@media (max-width: 1184px) {
    .login-popup__container {
        width: calc(100vw - 3rem);
        max-width: 24rem;
        max-height: calc(100vh - 1rem);
        overflow-y: auto;
    }

    .login-popup__form {
        padding: 1rem;
        padding-bottom: 0.5rem;
        padding-top: 1.5rem;
    }

    .main-login-form__bigCaption {
        margin-bottom: -18px;
    }

    .main-login-form__instructions {
        margin-top: 0;
    }

    .main-login-form__buttons {
        margin-top: 0;
        margin-bottom: 0;
    }

    .main-login-form__policies {
        margin-top: 0.5rem;
        text-align: center;
    }

    .___base-button {
        padding-left: 24px;
        padding-right: 12px;
    }
}

@media (max-width: 360px) {
    .login-popup__container {
        width: calc(100vw - 2rem);
    }
}
