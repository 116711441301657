.c-mobile-menu {
    display: none;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 102;
}

.mobile-menu-button {
    width: 3rem;
    height: 3rem;
    background-color: rgb(26, 26, 26);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: none;
    border: 0;
}

.mobile-menu-list {
    position: absolute;
    bottom: 3.5rem;
    right: 0;
    background-color: #fff;
    border-radius: 0.75rem;
    width: 9.0625rem;
    z-index: 100000;
    box-shadow: 0px 0px 0px 1px rgba(26, 26, 26, 0.1), 0px 2px 4px rgba(26, 26, 26, 0.1);
    padding-bottom: env(safe-area-inset-bottom);
}

.mobile-menu-list__service {
    width: 16rem;
}

.mobile-menu-list-item {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(26, 26, 26, 0.1);
    font-size: var(--font-body-font-size);
    line-height: var(--font-body-line-height);
    cursor: pointer;
    color: rgb(26, 26, 26);
    text-decoration: none;
}

.mobile-menu-list-item.cancel {
    display: none;
    align-items: center;
    height: 4rem;
    padding: 0.5rem;
    justify-content: center;
    box-sizing: border-box;
}

.mobile-menu-list-item:last-child {
    border-bottom: 0;
}

@media (max-width: 1183px) {
    .c-mobile-menu {
        display: block;
    }
}

@media (max-width: 599px) {
    .mobile-menu-list {
        position: fixed;
        width: 100vw;
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .mobile-menu-list-item.cancel {
        display: flex;
    }
}
