.SearchBar__input {
    width: 80%;
    box-shadow: none;
    padding: 1em;
    border-radius: 8px;
    border: 0;
}

.SearchBar__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -.5em;
}

.SearchBar__listItem {
    padding: 1em 1.5em;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    background-color: #d61448;
    box-shadow: none;
    cursor: pointer;
    border: 0;
    margin-top: .5em;
    margin-left: .5em;
}

.SearchBar__listItem:hover {
    background-color: #e21244;
}
