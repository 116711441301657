@font-face {
    font-family: "Graphik LC";
    src: url("../static/fonts/graphik-lc/Graphik-Regular.eot");
    src: url("../static/fonts/graphik-lc/Graphik-Regular.otf") format("opentype"),
         url("../static/fonts/graphik-lc/Graphik-Regular.woff") format("woff"),
         url("../static/fonts/graphik-lc/Graphik-Regular.woff2") format("woff2"),
         url("../static/fonts/graphik-lc/Graphik-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Graphik LC";
    src: url("../static/fonts/graphik-lc/Graphik-Semibold.eot");
    src: url("../static/fonts/graphik-lc/Graphik-Semibold.otf") format("opentype"),
        url("../static/fonts/graphik-lc/Graphik-Semibold.woff") format("woff"),
        url("../static/fonts/graphik-lc/Graphik-Semibold.woff2") format("woff2"),
        url("../static/fonts/graphik-lc/Graphik-Semibold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Graphik LC";
    src: url("../static/fonts/graphik-lc/Graphik-Bold.eot");
    src: url("../static/fonts/graphik-lc/Graphik-Bold.otf") format("opentype"),
        url("../static/fonts/graphik-lc/Graphik-Bold.woff") format("woff"),
        url("../static/fonts/graphik-lc/Graphik-Bold.woff2") format("woff2"),
        url("../static/fonts/graphik-lc/Graphik-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Graphik LC";
    src: url("../static/fonts/graphik-lc/Graphik LC-Regular Italic.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
}

:root {
    --font-family: "Graphik LC", Helvetica, Arial, sans-serif;

    --font-regular-weight: 400;
    --font-semibold-weight: 600;
    --font-bold-weight: 700;

    --font-display-font-size: 7.625rem;
    --font-display-line-height: 6rem;

    --font-header-font-size: 3.8125rem;
    --font-header-line-height: 4rem;

    --font-subheader-font-size: 2.5rem;
    --font-subheader-line-height: 2.6875rem;

    --font-lead-font-size: 1.875rem;
    --font-lead-line-height: 3rem;

    --font-body-font-size: 1.125rem;
    --font-body-line-height: 1.6875rem;

    --font-caption-font-size: 0.8125rem;
    --font-caption-line-height: 1.1875rem;
}

@media (max-width: 1183px) {
    :root {
        --font-display-font-size: 7.625rem;
        --font-display-line-height: 6rem;

        /* 38/44 */
        --font-header-font-size: 2.375rem;
        --font-header-line-height: 2.75rem;

        /* 23/32 */
        --font-subheader-font-size: 1.4375rem;
        --font-subheader-line-height: 2rem;

        /* 19/28 */
        --font-lead-font-size: 1.1875rem;
        --font-lead-line-height: 1.75rem;

        /* 16/24 */
        --font-body-font-size: 1rem;
        --font-body-line-height: 1.5rem;

        /* 13/19 */
        --font-caption-font-size: 0.8125rem;
        --font-caption-line-height: 1.1875rem;
    }
}
