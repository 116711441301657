footer {
    box-shadow: inset 0px 1px 0px rgba(26, 26, 26, 0.1);
    padding: 2rem 0;
    display: flex;
    align-items: center;
}

.footer-logo-container {
    height: 2.5rem;
    display: flex;
    align-items: center;
}

.footer-logo {
    width: 4.25rem;
}

.footer-content {
    display: grid;
    grid-template-columns: 16rem 1fr;
    grid-gap: 8rem;
    margin-left: 24.125rem;
    width: 100%;
}

.footerNoMargin .footer-content {
    margin-left: 0;
}

.footer-copyright {
    font-size: 0.8125rem;
    line-height: 1.1875rem;
    color: rgba(26, 26, 26, 0.4);
    height: 2.5rem;
    display: flex;
    align-items: center;
}

.footer-link {
    height: 2.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    line-height: 1.875rem;
    text-decoration: none;
    color: rgb(26, 26, 26);
}

.footer-link:hover {
    color: rgba(26, 26, 26, 0.7);
}

.footer-link.contact-us {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1184px) {
    footer {
        padding-top: 1.5rem;
        padding-bottom: 9rem;
    }

    .footer-content {
        margin-left: 0;
    }
}


@media (max-width: 1023px) {
    footer {
        padding-top: 1.5rem;
        padding-bottom: 7.25rem;
    }
}

@media (max-width: 799px) {
    .footer-content {
        margin-left: 0;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-gap: 1rem;
    }
}

@media (max-width: 599px) {
    footer {
        padding-top: 1.5rem;
        padding-bottom: 8.25rem;
    }
}

@media (max-width: 413px) {
    footer {
        padding-top: 1.5rem;
        padding-bottom: 5.5rem;
    }
}
